import VueRouter from "vue-router";
import i18n from "../i18n";
import Vue from "vue";
import SupportService from "@/api/serviceSupport";
import { sendMetaEvent } from "@/services/metaEvents.js";
const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/Landing'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/landings/telegram',
    name: 'LandingPid',
    component: () => import('@/views/TelegramLand'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/default/index/pid/:id',
    name: 'LandingPid',
    component: () => import('@/views/Landing'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/id:id(\\d+)',
    name: 'LandingRef',
    component: () => import('@/views/Landing'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: () => import('@/views/ConfirmEmail'),
    meta: {
      auth: undefined,
    },
  },
  {
    path: '/review',
    name: 'ReviewMain',
    component: () => import('@/views/ReviewMain'),
    meta: {
      auth: false,
      layout: 'review-layout',
      description: 'meta.description.review',
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/auth/index'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'recovery-password',
        name: 'RecoveryPassword',
        component: () => import('@/views/auth/RecoveryPassword'),
        meta: {
          auth: false,
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/auth/Register'),
        meta: {
          auth: false,
          layout: 'auth-layout',
          description: 'meta.description.register',
        },
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
        meta: {
          auth: false,
          layout: 'auth-layout',
          description: 'meta.description.login',
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
          auth: false,
          layout: 'auth-layout',
          description: 'meta.description.reset_password',
        },
      },
    ],
  },

  {
    path: '/panel',
    name: 'Layout',
    component: () => import('@/views/Panel/Layout'),
    meta: {
      auth: false,
      layout: 'panel-layout',
    },
    children: [
      {
        path: '/panel/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Panel/Dashboard'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: '/panel/sources',
        name: 'Sources',
        component: () => import('@/views/Panel/Sources'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: '/panel/banned',
        name: 'Banned',
        component: () => import('@/views/Panel/Banned'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/Panel/Profile'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'user-level',
        name: 'UserLevel',
        component: () => import('@/views/Panel/UserLevel'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'api',
        name: 'Api',
        component: () => import('@/views/Panel/Api'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'insight',
        name: 'Insight',
        component: () => import('@/views/Panel/Insight'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      // todo: remove when not needed {
      //   path: 'api',
      //   name: 'Api',
      //   component: () => import('@/views/Panel/Api'),
      //   meta: {
      //     auth: true,
      //     layout: 'panel-layout',
      //   },
      // },

      {
        path: 'notices',
        name: 'Notices',
        component: () => import('@/views/Panel/Notices'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'referrals',
        name: 'Referrals',
        component: () => import('@/views/Panel/Referrals'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'finances',
        name: 'Finances',
        component: () => import('@/views/Panel/Finances'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import('@/views/Panel/Support'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'support-chat/:id',
        name: 'SupportChat',
        component: () => import('@/views/Panel/SupportChat'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'review',
        name: 'ReviewPanel',
        component: () => import('@/views/Panel/ReviewPanel'),
        meta: {
          auth: true,
          layout: 'panel-layout',
          description: 'meta.description.review',
        },
      },
      {
        path: 'pages/:id',
        name: 'PanelPages',
        component: () => import('@/views/Panel/PanelPages'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'bonuses',
        name: 'Bonuses',
        component: () => import('@/views/Panel/Bonuses'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'finish-registration',
        name: 'FinishRegistration',
        component: () => import('@/views/Panel/FinishRegistration.vue'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'audiences',
        name: 'Audiences',
        component: () => import('@/views/Panel/Audiences'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
        children: [
          {
            path: 'create',
            name: 'CreateAudience',
            component: () => import('@/views/Panel/CreateAudience'),
            meta: {
              auth: true,
              layout: 'panel-layout',
            },
          },
          {
            path: 'edit/:id',
            name: 'EditAudience',
            component: () => import('@/views/Panel/EditAudience'),
            meta: {
              auth: true,
              layout: 'panel-layout',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/advertiser',
    name: 'Advertiser',
    component: () => import('@/views/Panel/Advertiser/Layout'),
    meta: {
      auth: true,
      layout: 'panel-layout',
    },
    children: [
      {
        path: 'source/:id',
        name: 'Source',
        component: () => import('@/views/Panel/Advertiser/Source'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'create-campaign',
        name: 'CreateCampaign',
        component: () => import('@/views/Panel/Advertiser/CreateCampaign'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'edit-campaign/:id',
        name: 'EditCampaign',
        component: () => import('@/views/Panel/Advertiser/EditCampaign'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'campaigns',
        name: 'Campaigns',
        component: () => import('@/views/Panel/Advertiser/Campaigns'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
        children: [
          {
            path: 'page/:id',
            name: 'CampaignPageGroup',
            component: () => import('@/views/Panel/Advertiser/CampaignPage'),
            meta: {
              auth: true,
              layout: 'panel-layout',
            },
          },
        ],
      },
      {
        path: 'group',
        name: 'Group',
        component: () => import('@/views/Panel/Advertiser/Group'),
        meta: {
          auth: true,
          layout: 'panel-layout',
          title: 'push.group',
        },
      },
      {
        path: 'groups',
        name: 'Groups',
        component: () => import('@/views/Panel/Advertiser/Groups'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: () => import('@/views/Panel/Advertiser/Statistics'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'postback',
        name: 'Postback',
        component: () => import('@/views/Panel/Advertiser/Postback'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'tracker',
        name: 'Tracker',
        component: () => import('@/views/Panel/Advertiser/Tracker'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'group-template/:id',
        name: 'EditGroupTemplate',
        component: () => import('@/views/Panel/Advertiser/EditGroupTemplate'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
      {
        path: 'new-group-template/',
        name: 'NewGroupTemplate',
        component: () => import('@/views/Panel/Advertiser/NewGroupTemplate'),
        meta: {
          auth: true,
          layout: 'panel-layout',
        },
      },
    ],
  },

  {
    path: '/pages/:id',
    name: 'Pages',
    component: () => import('@/views/Pages'),
    meta: {
      auth: undefined,
      layout: 'page-layout',
      description: 'meta.description.',
    },
  },
  {
    path: '/join-us',
    name: 'JoinUs',
    component: () => import('@/views/JoinUs'),
    props: (route) => ({ pid: route.query.pid || null }),
    meta: {
      auth: false,
      layout: 'default-layout',
      description: 'meta.description.join_us',
    },
  },
  {
    path: '/rtb',
    name: 'RTB',
    component: () => import('@/views/ProgrammaticIntegration.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: () => import('@/views/ErrorPage'),
    meta: {
      auth: undefined,
      layout: 'review-layout',
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.description) {
    let customDescription;
    if (to.name === "Pages") {
      customDescription = i18n.t(`${to.meta.description}${to.params.id}`);
    } else {
      customDescription = i18n.t(to.meta.description);
    }
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", customDescription);
  } else {
    // If there's no meta description for the route, fallback to a default one based on i18n
    const defaultDescription = i18n.t("meta.description.default");
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", defaultDescription);
  }
  Vue.nextTick(() => {
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute(
        "href",
        `${window.location.protocol}//${window.location.host}${to.path}`
      );
  });
  next();
});

router.beforeEach(async (to, from, next) => {
  if (to.path !== "/panel/finances") {
    sendMetaEvent(to.path, "PageView");
  }

  if (to.path === "/auth/login" && to.query?.secret) {
    await SupportService.getTokenAdmin(to.query.secret)
      .then((res) => {
        if (res && res.status === 200) {
          localStorage.setItem("guestToken", res.data.token);
        }
      })
      .catch((error) => console.error(error));
  }

  const isAuthorized =
    localStorage.getItem("guestToken") || localStorage.getItem("authToken");
  const isBanned = localStorage.getItem("ban_reason");

  if (to.meta.auth === true && isBanned && to.name !== "Banned") {
    next("/panel/banned");
  } else if (
    to.meta.auth === true &&
    isAuthorized &&
    to.name === "Banned" &&
    !isBanned
  ) {
    next("/panel/dashboard");
  } else if (
    ((to.meta.auth === true && !isAuthorized) || to.path === "/auth") &&
    to.path !== "/auth/login"
  ) {
    next("/auth/login");
  } else if (to.meta.auth === false && isAuthorized) {
    next("/panel/dashboard");
  } else {
    next();
  }
});

router.onError((error) => {
  if (
    error?.code === "CSS_CHUNK_LOAD_FAILED" ||
    error?.name === "ChunkLoadError"
  ) {
    window.location.reload()
  }
});

export default router;
